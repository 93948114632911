import React, {useState,useEffect} from 'react';
import './Modal.css';
import modalContext from "./ModalContext";

export const Modal = ({component, open, width}) => {
  const { modalOpen, setModalOpen } = React.useContext(modalContext);
  const { modalComponent, setModalComponent } = React.useContext(modalContext)

  const [activeBar, setActiveBar] = useState(0)
  useEffect(() => {
    if(activeBar !== 60) {
      setTimeout(function () {
        setActiveBar( activeBar + 1 );
      }, 100);
    } else {
      setTimeout(function () {
        setActiveBar(0 );
      }, 8000);
    }
  }, [activeBar])

  return (
    <div className={`modal-container ${modalOpen ? 'modal-open' : 'modal-closed'}`}>
      <div className={'modal-inner-container'} style={{maxWidth: width}}>
      <svg className={'modal-close-button'} onClick={() => setModalOpen(false)}  width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z"/></svg>
        {component}
      </div>
      <div style={{zIndex: 999}} className={'graph-animation-container'}>
      {
        [...Array(60).keys()].map((el, i) => {
          return (
            <div style={{zIndex: 999, background: `${i % 2 == 0 ? '#DDEAFDaa' : '#1F3C8233'}`}} className={`graph-animation-bar ${activeBar > i ? 'active-bar' : ''}`}></div>
          )
        })
      }
      </div>
    </div>
  )
}

export default Modal;
