import React from 'react'
import StripeForm from './StripeForm';
import Card from '../components/card/Card';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import {Typography} from '@material-ui/core';
const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const Stripe = ({ title, description, amount, currency, paymentSuccessful }) => {

  const handlePaymentSuccessful = (bool, returnedValue) => {
    paymentSuccessful(bool, returnedValue)
  }
return(
<Card background={'none'} >
<Elements stripe={stripePromise}>
  <StripeForm title={title} description={description} amount={amount} currency={currency} paymentSuccessful={(e, r) => handlePaymentSuccessful(e, r)}/>
</Elements>
</Card>
)
}
export default Stripe;
