import logo from './assets/sefer-foundation-logo.png';
import poster from './assets/sefer-foundation-grand-rebbe-of-kazimierz-klezzmessengers.jpeg';
import React, {useState, useEffect, useContext} from 'react';
import './App.css';
import Modal from './components/modal/Modal';
import modalContext from './components/modal/ModalContext';
import Stripe from './stripe/StripeContainer';

function App() {
  const [modalOpen, setModalOpen] = React.useState(false);
  const [modalComponent, setModalComponent] = React.useState(null);
  const [donationAmount, setDonationAmount] = useState(null)
  const [customDonationAmount, setCustomDonationAmount] = useState(null)
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);

  useEffect(() => {
    if(donationAmount) {
      setModalOpen(true);
    }
  }, [donationAmount])

  const handleDonation = (amount) => {
    setDonationAmount(amount)
  }

  const handlePaymentSuccessful = () => {
    setPaymentSuccessful(true)
  }

  return (
    <>
    <modalContext.Provider value={{ modalOpen, setModalOpen, modalComponent, setModalComponent }}>
      <div className={'outer-container'}>
        <div className="container">
          <div className={'logo-container'}>
            <img className={'logo'} src={logo} />
          </div>
          <h1>A Message to Friends of the Sefer Foundation</h1>
          <h4>Dear Sir/Madam</h4>
          <p>The awareness of the legacy left behind by the founders of the philosophy and culture of Hasidic Galicia has inspired us with the idea of establishing an institution serving as a landmark for all those who wish to make pilgrimages to its sources. This institution is the Sefer Foundation of which I am the President and Founder. </p>
          <p>The seat of the Foundation is Krakow, a city blessed by the life and work of great rabbis. Despite the passage of time, their spirituality is still present and their thought is very much up-to-date and necessary as always. </p>
          <p>The purpose of the Sefer Foundation is, among other things, to provide care and reliable information to pilgrims visiting Galicia.</p>
          <p>The Foundation, with the help of all people who would like to spread the good and wise word and who want to support pilgrims coming to Galicia, is building a haven for them, a tangible sign of the openness and durability of what was at the heart of Hasidic thought and culture. </p>
          <p>Bearing in mind the above, we would like to invite you to cooperate as our common goals may unite our efforts and help us reach them. </p>
          <p>We look forward to hearing from you.</p>
          <p>Yours faithfully</p>
          <h6>Michel Mandel</h6>
          <h4>Email</h4>
          <p>michel@seferfoundation.org</p>
          <h4>Phone</h4>
          <p>+48537811909</p>
            <p>If you wish to support the Sefer Foundation's work & help us preserve the Rabbinic heritage in Krakow, please make a donation.</p>
            <div className={'donation-button-container'}>
            <div style={{display: 'flex', width: '100%'}}>
            <input type="number" onKeyUp={(e) => setCustomDonationAmount(e.target.value)} ></input>
            <button onClick={() => setDonationAmount(customDonationAmount)} className={'custom-donation-button donation-button'}>Donate</button>
            </div>
            </div>


        </div>
      </div>
    <Modal open={modalOpen} component={
        <>
        {!paymentSuccessful ? <Stripe
        title={'Sefer Foundation Donations'}
        description={'Revitalising the Jewish life in Krakow'}
        amount={donationAmount}
        currency={'usd'}
        paymentSuccessful={(e, r) => handlePaymentSuccessful(e, r)}
        />
        : <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '360px'}}><div><h4 style={{textAlign: 'center'}}>Payment Successful!</h4><h4 style={{textAlign: 'center'}}>Thank you for your donation!</h4></div></div>
      }
        </>
      }/>
      </modalContext.Provider>
    </>
  );
}

export default App;
