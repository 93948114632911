import React, {useState, useEffect} from "react";
import './StripeForm.css'
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement, PaymentRequestButtonElement } from "@stripe/react-stripe-js";
import {Button, Typography, InputLabel, CircularProgress} from '@material-ui/core'
import { handleStripePayment } from '../api';

export const StripeForm = ({title, description, amount, currency, paymentSuccessful}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentRequest, setPaymentRequest] = useState(null)
  const [usingThirdPartyWallet, setUsingThirdPartyWallet] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [errMessage, setErrMessage] = useState('')
  const [customerEmail, setCustomerEmail] = useState('');
  const [customerName, setCustomerName] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });
    if (!error) {
      sendPayment({
        token: paymentMethod.id,
        name: customerName,
        email: customerEmail,
        title: customerName,
        description: description,
        amount: amount,
        currency: currency,
      });
    } else {
      setIsLoading(false);
    }
  };

  const sendPayment = async (payload) => {
      try {
        const payment = await handleStripePayment(payload)
        setIsLoading(false);
        if(payment.success) {
          paymentSuccessful(true, payment)
        } else {
          setErrMessage('Payment Failed');
        }
      } catch (err) {
          setIsLoading(false)
      }
  }

  const handlePaymentMethodReceived = (e) => {
    console.log(e);
    e.complete('success');
    sendPayment({
      token: e.paymentMethod.id,
      name: e.paymentMethod.name,
      email: customerEmail,
      title: customerName,
      description: description,
      amount: amount,
      currency: currency,
    });
    setIsLoading(false);
    paymentSuccessful(true, e)
  }

  const handleThirdPartyPaymentComplete = (e) => {
    console.log(e)
  }

  useEffect(() => {
    if (stripe && currency && amount) {
      console.log('Triggering payment request')
      console.log(amount)
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Sefer Foundation Donation',
          amount: amount * 100,
        }
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          console.log(result)
          setPaymentRequest(pr);
        }
      });

      pr.on('paymentmethod', handlePaymentMethodReceived);
      pr.on('complete', handleThirdPartyPaymentComplete)
      pr.on('cancel', () => {
        pr.off('paymentmethod');
      });

    }
  }, [stripe, currency, amount]);

  return (
    <form onSubmit={handleSubmit}>
      <h4>
        Payment Details
      </h4>
      <InputLabel shrink >Full Name</InputLabel>
      <input onChange={(e) => setCustomerName(e.target.value) } style={{background: '#fff', color:'#000', borderTop: 'none', borderRight: 'none',  borderLeft: 'none'}} />
      <InputLabel shrink >Email</InputLabel>
      <input onChange={(e) => setCustomerEmail(e.target.value) } style={{background: '#fff', color:'#000', borderTop: 'none', borderRight: 'none',  borderLeft: 'none'}} />
      <br/>
      {
        paymentRequest ?
        <PaymentRequestButtonElement options={{paymentRequest}} />
        :
        <div style={{display: 'flex', justifyContent: 'center'}}><CircularProgress /></div>
      }
      <hr/>
          <p style={{color: '#fff'}}>Or pay with card</p>
      <hr/>

      <InputLabel shrink >Card Number</InputLabel>
      <CardNumberElement />
      <InputLabel shrink>Expiry Date</InputLabel>
      <CardExpiryElement />
      <InputLabel shrink>CVC Code</InputLabel>
      <CardCvcElement />
      { !isLoading &&
        <Button className={'payment-btn'} onClick={handleSubmit} variant={'outlined'}>Pay</Button>
      }
      {
        isLoading &&
        <div style={{display: 'flex', justifyContent: 'center'}}><CircularProgress /></div>
      }
      {
        errMessage &&
        <Typography style={{color: '#f00', marginTop: '14px'}} align={'center'} variant={'subtitle2'}>{errMessage}</Typography>
      }
    </form>
  );
};

export default StripeForm;
